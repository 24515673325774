import Transform from "images/key-capabilities/Transform.png";
import Stickers from "images/key-capabilities/Stickers.png";
import Filters from "images/key-capabilities/Filters.png";
import CustomizableUI from "images/key-capabilities/CustomizableUI.png";
import Adjustment from "images/key-capabilities/Adjustment.png";
import Brush from "images/key-capabilities/Brush.png";
import Frames from "images/key-capabilities/Frames.png";
import Focus from "images/key-capabilities/Focus.png";
import Empty from "images/key-capabilities/Empty.png";
import Toolbar from "images/key-capabilities/Toolbar.png";
import web from "../../../../.remote/gatsby-imgly-source/main/docs/shared/images/web.jpg";
import * as React from 'react';
export default {
  Transform,
  Stickers,
  Filters,
  CustomizableUI,
  Adjustment,
  Brush,
  Frames,
  Focus,
  Empty,
  Toolbar,
  web,
  React
};