import Transform from 'images/key-capabilities/Transform.png';
import Text from 'images/key-capabilities/TextEditing.png';
import Adjustment from 'images/key-capabilities/Adjustment.png';
import Stickers from 'images/key-capabilities/Stickers.png';
import Filters from 'images/key-capabilities/Filters.png';
import Frames from 'images/key-capabilities/Frames.png';
import Brush from 'images/key-capabilities/Brush.png';
import CustomizableUI from 'images/key-capabilities/CustomizableUI.png';
import * as React from 'react';
export default {
  Transform,
  Text,
  Adjustment,
  Stickers,
  Filters,
  Frames,
  Brush,
  CustomizableUI,
  React
};